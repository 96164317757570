import {
  Dialog,
  DialogProps,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import React from 'react';
import CloseIcon from 'src/assets/icons/close.svg';
import useStyles from './styles';

export interface BaseDialogProps extends DialogProps {
  showCloseIcon?: boolean;
  closeIconProps?: IconButtonProps;
}

export const BaseDialog: React.FCC<BaseDialogProps> = ({
  showCloseIcon,
  closeIconProps,
  onClose,
  children,
  classes,
  ...rest
}) => {
  const { classes: styles, cx } = useStyles();

  return (
    <Dialog
      onClose={onClose}
      scroll="body"
      classes={{
        ...classes,
        paper: cx(styles.paper, classes?.paper),
      }}
      slotProps={{ backdrop: { unmountOnExit: true } }}
      keepMounted
      {...rest}>
      {showCloseIcon && onClose && (
        <IconButton
          {...closeIconProps}
          onClick={(e) => {
            onClose(e, 'escapeKeyDown');
            closeIconProps?.onClick?.(e);
          }}
          className={closeIconProps?.className}
          size="large"
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            zIndex: 999,
            ...closeIconProps?.sx,
          }}>
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
};
